<script>
import { PolarArea } from 'vue-chartjs'

export default {
  extends: PolarArea,
  props: {
    data: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        if (val.labels) {
          const dataset = {
            labels: val.labels || [],
            datasets: val.datasets || [],
          }
          this.renderChart(dataset, this.options)
        }
      },
    },
  },
  mounted() {
    this.renderChart(this.data, this.options)
  },
}
</script>
