<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-header>
      <b-card-title class="mb-1">
        {{ title }}
      </b-card-title>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-polar-area-chart
        :height="750"
        :data="dataset"
        :options="chartjsData.polarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import ChartjsComponentPolarAreaChart from './charts-components/ChartjsComponentPolarAreaChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    ChartjsComponentPolarAreaChart,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
  },
  props: {
    yearPolarChartData: {
      type: [Object, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
    title: {
      type: [String, Number, null],
      default: '',
    },
  },
  data() {
    return {
      chartjsData,
      dataset: {},
    }
  },
  watch: {
    yearPolarChartData: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$set(this.dataset, 'labels', val.labels)
        this.$set(this.dataset, 'datasets', val.datasets)
      },
    },
  },
}
</script>
