<template>
  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Appointments Reports
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="appointmentReportForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="generateReport">
        <b-card
          header-tag="header"
        >
          <b-row>
            <b-col cols="md-12">
              <b-form-group
                label="By"
                label-for="h-reportType"
                label-cols-md="1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Report Type"
                  vid="reportType"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-reportType"
                    v-model="reportType"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                  >
                    <b-form-radio
                      v-for="(option, index) in reportOptions"
                      :key="index"
                      :value="option.value"
                    >
                      <span class="align-middle">{{ option.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="reportTypeValidation"
                    class="text-danger"
                  >
                    {{ reportTypeError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-12">
              <validation-provider
                #default="{ errors }"
                name="Activity Type(s)"
                vid="service"
                rules="required"
              >
                <b-form-group
                  label="Activity Type(s)"
                  label-for="h-user-service"
                  label-cols-md="1"
                  :state="(errors.length > 0 || activityTypeValidation) ? false : null"
                >
                  <v-select
                    id="h-user-service"
                    v-model="activityType"
                    label="typeName"
                    multiple
                    :options="activityTypeOptions"
                    :reduce="typeName => typeName._id"
                    :clearable="true"
                    @input="updateActivityTypeData"
                  >
                    <template #option="{ status, typeName }">
                      <span>{{ typeName }}</span>
                      <span
                        v-if="status == 'unpublished'"
                        class="text-capitalize"
                      >
                        ({{ status }})
                      </span>
                    </template>
                    <template #selected-option="{ status, typeName }">
                      <span>{{ typeName }}</span>
                      <span
                        v-if="status == 'unpublished'"
                        class="text-capitalize"
                      >
                        ({{ status }})
                      </span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="activityTypeValidation"
                    class="text-danger"
                  >
                    {{ activityTypeError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="md-12"
            >
              <validation-provider
                #default="{ errors }"
                name="Year"
                vid="year"
                rules="required"
              >
                <b-form-group
                  label="Year"
                  label-for="h-user-year"
                  label-cols-md="1"
                  :state="(errors.length > 0 || yearValidation) ? false : null"
                >
                  <b-form-tags
                    v-model="year"
                    size="lg"
                    add-on-change
                    no-outer-focus
                  >
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1 user__lists"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="danger"
                            @remove="removeTag(tag)"
                          >
                            {{ tag }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                      >
                        <template v-slot:first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option
                            disabled
                            value=""
                          >
                            Choose year(s)...
                          </option>
                        </template>
                        <option
                          v-for="(opt, ind) in yearOptions"
                          :key="ind"
                          :value="opt.name"
                        >
                          {{ opt.name }}
                        </option>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="yearValidation"
                    class="text-danger"
                  >
                    {{ yearError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-6">
              <b-form-checkbox
                v-if="reportType == 'year'"
                v-model="trendLine"
                value="accepted"
                unchecked-value=""
                class="custom-control-primary"
              >
                Add trendline to charts
              </b-form-checkbox>
            </b-col>
            <b-col cols="md-6">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="float-right"
                :disabled="invalid"
                @click="generateReport"
              >
                <span class="align-middle">Generate Report</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

    <b-card
      v-if="reportType == 'year' && showYearReportCard"
      header-tag="header"
      class="chart-card report__card"
    >
      <template #header>
        <h3 class="align-middle mr-auto">
          Appointments (Year to Year)
        </h3>
        <b-button
          type="button"
          variant="flat-primary"
          class="float-right"
          @click="downloadCSV('yearTable', 'Appointments-Year-Reports')"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
            size="20"
          />
          <span class="">Download Excel</span>
        </b-button>
      </template>
      <b-row>
        <b-col
          cols="12"
          class="item__description_table"
        >
          <b-table
            id="yearTable"
            class="position-relative has_padding event_list"
            :items="yearTableData"
            responsive
            :fields="yearTableColumns"
            :sort-by.sync="yearTableSortBy"
            show-empty
            no-sort-reset
            empty-text="No matching records found"
            :sort-desc.sync="yearTableIsSortDirDesc"
            :sort-compare="sortYearTable"
          >
            <template #cell(year)="data">
              <div class="text-nowrap">
                <span class="text-bold-black">{{ resolveYearName(data.item.year) }}</span>
              </div>
            </template>
            <template
              v-for="flow in activityTypeWithName"
              v-slot:[`cell(${flow._id})`]="data"
            >
              <!-- eslint-disable -->
              <div class="text-nowrap">
                <p
                  class="mb-0"
                  v-html="data.item[flow._id]"
                />
              </div>
              <!-- eslint-enable -->
            </template>
            <template #cell(total)="data">
              <div class="text-nowrap">
                <!-- eslint-disable -->
                <p
                  class="mb-0"
                  v-html="data.item.total"
                />
                <!-- eslint-enable -->
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div class="chats__block">
        <b-row class="match-height">
          <b-col
            cols="12"
          >
            <chartjs-bar-chart
              :title="'Appointments (Year to Year)'"
              :year-bar-chart-data="yearBarChartData"
            />
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            v-for="(item, index) in yearPolarChartData"
            :key="index"
            cols="4"
          >
            <chartjs-polar-area-chart
              :title="item.name"
              :year-polar-chart-data="item.data"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div v-if="reportType == 'month' && showMonthReportCard">
      <b-card
        v-for="(yearData, key) in monthReportData"
        :key="key"
        header-tag="header"
        class="chart-card report__card"
      >
        <template #header>
          <h3 class="align-middle mr-auto">
            Appointments (YTD {{ yearData._id }})
          </h3>
          <b-button
            type="button"
            variant="flat-primary"
            class="float-right"
            @click="downloadCSV(`monthTable${key}`, `${yearData._id}-Appointments-Month-Reports`)"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
              size="20"
            />
            <span class="">Download Excel</span>
          </b-button>
        </template>
        <b-row>
          <b-col
            cols="12"
            class="item__description_table"
          >
            <b-table
              :id="'monthTable'+key"
              class="position-relative has_padding event_list"
              :items="monthTableData[key]"
              responsive
              :fields="monthTableColumns"
              :sort-by.sync="monthTableSortBy"
              show-empty
              no-sort-reset
              empty-text="No matching records found"
              :sort-desc.sync="monthTableIsSortDirDesc"
              :sort-compare="sortYearTable"
              :tbody-tr-class="rowClass"
            >
              <template #cell(agent)="data">
                <div class="text-nowrap">
                  <span class="text-bold-black">{{ data.item.agent }}</span>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        header-tag="header"
        class="chart-card report__card"
      >
        <div class="chats__block">
          <b-row class="match-height">
            <b-col
              cols="12"
            >
              <chartjs-line-chart
                :title="''"
                :month-line-chart-data="monthLineChartData"
              />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BButton, BFormTags, BFormTag, BFormSelect, BFormGroup, BFormRadio, BFormRadioGroup,
  BFormCheckbox, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ChartjsBarChart from './chartjs/ChartjsBarChart.vue'
import ChartjsLineChart from './chartjs/ChartjsLineChart.vue'
import ChartjsPolarAreaChart from './chartjs/ChartjsPolarAreaChart.vue'

const timezone = process.env.VUE_APP_TIME_ZONE

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormTags,
    BFormTag,
    BFormSelect,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BTable,

    // charts
    ChartjsBarChart,
    ChartjsLineChart,
    ChartjsPolarAreaChart,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showYearReportCard: false,
      showMonthReportCard: false,
      showUserReportCard: false,
      reportType: 'year',
      activityType: ['all'],
      year: [],
      user: [],
      month: [],
      singleYear: '',
      trendLine: 'accepted',
      activityTypeWithName: [],
      userWithName: [],
      userReportTitle: '',
      activityTypeError: 'Valid activityType is required',
      activityTypeValidation: false,
      reportTypeError: 'Valid report type is required',
      reportTypeValidation: false,
      yearError: 'Valid year is required',
      yearValidation: false,
      userError: 'Valid user is required',
      userValidation: false,
      singleYearError: 'Valid year is required',
      singleYearValidation: false,
      monthError: 'Valid month is required',
      monthValidation: false,
      activityTypeOptions: [
        {
          typeName: 'All',
          _id: 'all',
        },
      ],
      onlyActivityTypeOptions: [],
      yearOptions: [],
      userOptions: [
        {
          name: 'All',
          _id: 'all',
        },
      ],
      onlyUserOptions: [],
      reportOptions: [
        { text: 'By Years (With Comparison)', value: 'year' },
        { text: 'By months (With comparison)', value: 'month' },
      ],
      monthOptions: [
        { key: 'all', title: 'All' },
        { key: '1', title: 'Jan' },
        { key: '2', title: 'Feb' },
        { key: '3', title: 'Mar' },
        { key: '4', title: 'Apr' },
        { key: '5', title: 'May' },
        { key: '6', title: 'Jun' },
        { key: '7', title: 'Jul' },
        { key: '8', title: 'Aug' },
        { key: '9', title: 'Sep' },
        { key: '10', title: 'Oct' },
        { key: '11', title: 'Nov' },
        { key: '12', title: 'Dec' },
      ],
      monthOptionsOnly: [
        { key: '1', title: 'Jan' },
        { key: '2', title: 'Feb' },
        { key: '3', title: 'Mar' },
        { key: '4', title: 'Apr' },
        { key: '5', title: 'May' },
        { key: '6', title: 'Jun' },
        { key: '7', title: 'Jul' },
        { key: '8', title: 'Aug' },
        { key: '9', title: 'Sep' },
        { key: '10', title: 'Oct' },
        { key: '11', title: 'Nov' },
        { key: '12', title: 'Dec' },
      ],
      yearReportTableData: [],
      monthReportData: [],
      userReportData: [],
      userReportGrandTotalData: [],
      monthReportCountData: [],
      colorOptions: [
        '#104D9D', '#179CA6', '#F2B231', '#F36554', '#383D3B', '#6F5F75', '#9883AB', '#EB212E', '#F7A1A3', '#FF4D80',
        '#85B79D', '#21BB8B', '#9999A1', '#808772', '#51513D', '#A57548', '#CBA5A2', '#8C4843', '#633042', '#57276E',
        '#ACC18A', '#9DC7C8', '#98D2EB', '#119285', '#95D082', '#DFDF2F', '#AB966D', '#D2AD63', '#FF9F43', '#FF5C00',
        '#146ADC', '#150578', '#7BDFF2', '#8FD225', '#BBADA0', '#C099E3', '#FF595E', '#D0520B', '#F27A6B', '#C33C54',
      ],
      yearBarChartData: {},
      monthLineChartData: {},
      userLineChartData: {},
      yearPolarChartData: [],
      yearTableColumns: [],
      yearTableData: [],
      yearTableSortBy: 'year',
      yearTableIsSortDirDesc: true,
      monthTableColumns: [],
      monthTableData: [],
      monthTableSortBy: 'agent',
      monthTableIsSortDirDesc: false,

      // validation rules
      required,
    }
  },
  beforeMount() {
    this.$http.get('operation/reports/appointments/extra-options')
      .then(response => {
        this.onlyActivityTypeOptions = response.data.activityTypeOptions
        this.activityTypeOptions = this.activityTypeOptions.concat(response.data.activityTypeOptions ?? [])
        // this.yearOptions = response.data.yearOptions ?? []
        const currentYear = new Date().getFullYear()
        // eslint-disable-next-line no-plusplus
        for (let year = process.env.VUE_APP_REPORT_YEAR; year <= currentYear; year++) {
          const yearObj = {
            _id: year,
            name: `${year}`,
          }
          this.yearOptions.push(yearObj)
        }
        this.year = this.yearOptions.slice(-3).map(a => a.name)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.bold === true) {
          return 'text-bold-black'
        }
      }
      return null
    },
    sortYearTable(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      const canSortA = aRow.sortable
      const canSortB = bRow.sortable
      // if (canSortA && !canSortB) {
      //   console.log('ok')
      //   return -1
      // }
      if ((!canSortA || !canSortB) && sortDesc) {
        return -1
      }
      if ((!canSortA || !canSortB) && !sortDesc) {
        return 1
      }
      const a = aRow[key]
      const b = bRow[key]
      // if (typeof Number(b) === 'number' && typeof a === 'string' && sortDesc) {
      //   return -1
      // }
      if (
        (typeof a === 'number' && typeof b === 'number')
        || (a instanceof Date && b instanceof Date)
      ) {
        // eslint-disable-next-line no-nested-ternary
        return a < b ? -1 : (a > b ? 1 : 0)
      }
      return this.toString(a).localeCompare(this.toString(b), compareLocale, compareOptions)
    },
    toString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      }
      if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => this.toString(value[key]))
          .join(' ')
      }
      return String(value)
    },
    updateActivityTypeData() {
      this.activityTypeValidation = false
      if (this.activityType.includes('all')) {
        this.activityType = this.activityType.filter(el => el === 'all')
      }
    },
    downloadCSV(reference, fileName, separator = ',') {
      // let csvData = []
      // const rows = document.getElementById(reference).getElementsByTagName('tr')
      // // eslint-disable-next-line no-plusplus
      // for (let i = 0; i < rows.length; i++) {
      //   const cols = rows[i].querySelectorAll('td, th')
      //   const csvRow = []
      //   // eslint-disable-next-line no-plusplus
      //   for (let j = 0; j < cols.length; j++) {
      //     csvRow.push(cols[j].textContent)
      //   }

      //   csvData.push(csvRow.join(','))
      // }
      // csvData = csvData.join('\n')

      // const CSVFile = new Blob([csvData], { type: 'text/csv' })

      // const tempLink = document.createElement('a')

      // tempLink.download = `${fileName}.csv`
      // const url = window.URL.createObjectURL(CSVFile)
      // tempLink.href = url

      // tempLink.style.display = 'none'
      // document.body.appendChild(tempLink)
      // tempLink.click()
      // document.body.removeChild(tempLink)
      const rows = document.getElementById(reference).getElementsByTagName('tr')
      const csv = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < rows.length; i++) {
        const row = []; const
          cols = rows[i].querySelectorAll('td, th')
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < cols.length; j++) {
          const clone = cols[j].cloneNode(true)
          const spans = clone.querySelector('.sr-only')
          if (spans) {
            clone.removeChild(spans)
          }

          const result = clone.textContent || clone.innerText
          let data = result.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
          data = data.replace(/"/g, '""')
          row.push(`"${data}"`)
        }
        csv.push(row.join(separator))
      }
      const csvString = csv.join('\n')
      // Download it
      const filename = `${fileName}.csv`
      const link = document.createElement('a')
      link.style.display = 'none'
      link.setAttribute('target', '_blank')
      link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvString)}`)
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    generateReport() {
      this.$refs.appointmentReportForm.validate().then(success => {
        if (success) {
          if (this.reportType === 'year') {
            this.generateYearReport()
          } else if (this.reportType === 'month') {
            this.generateMonthReport()
          }
        }
      })
    },
    resolveSFCount(month, data) {
      const found = data.find(o => o.month === month)
      if (found) {
        return found.count
      }
      return 0
    },
    generateYearReport() {
      const formData = new FormData()

      formData.append('activityType', JSON.stringify(this.activityType))

      formData.append('activityTypeAll', JSON.stringify(this.onlyActivityTypeOptions.map(flow => flow._id)))
      formData.append('year', JSON.stringify(this.year))
      formData.append('trendLine', this.trendLine)
      this.$http.post('operation/reports/generate/appointments/year', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.bookingsData) {
            this.yearPolarChartData = []
            this.yearReportTableData = response.data.bookingsData
            this.yearTableIsSortDirDesc = true
            const yearBarChartLabels = []
            const yearBarChartDataset = []
            if (this.activityType.includes('all')) {
              this.activityTypeWithName = this.onlyActivityTypeOptions
            } else {
              this.activityTypeWithName = this.onlyActivityTypeOptions.filter(el => this.activityType.includes(el._id))
            }
            this.yearTableColumns = []
            this.yearTableColumns.push({ key: 'year', label: 'Total Service (YTD)', sortable: true })
            this.activityTypeWithName.forEach(element => {
              this.yearTableColumns.push({ key: element._id, label: `${element.typeName} ${element.typeNameSG || ''}`, sortable: false })
              yearBarChartLabels.push(element.typeName)
            })
            this.yearTableColumns.push({ key: 'total', label: 'Total 共计', sortable: false })
            this.yearTableData = []
            response.data.bookingsData.forEach((element, colorKey) => {
              const yearData = []
              const yearPolarChartLabels = []
              const polarColors = []
              const dataObject = {}

              dataObject.year = this.resolveYearName(element._id)
              this.activityTypeWithName.forEach(flow => {
                const count = this.resolveActivityTypeDataInYear(flow._id, element._id)

                dataObject[flow._id] = count
                yearData.push(count)
                polarColors.push(flow.color)
                // yearBarChartLabels.push(flow.name)
                let percentage = 0
                if (element.totalThisYear === 0) {
                  percentage = (0).toFixed(2)
                } else {
                  percentage = ((count / element.totalThisYear) * 100).toFixed(2)
                }
                yearPolarChartLabels.push(`${flow.typeName}, ${percentage}%`)
              })

              dataObject.total = element.totalThisYear
              dataObject.sortable = true

              const polarChartObject = {

                name: `${element._id}, Total ${element.totalThisYear}`,
                data: {
                  labels: yearPolarChartLabels,
                  datasets: [
                    {
                      label: 'Population (millions)',
                      backgroundColor: polarColors,
                      data: yearData,
                      borderWidth: 0,
                    },
                  ],
                },
              }
              this.yearPolarChartData.push(polarChartObject)
              this.yearTableData.push(dataObject)

              const color = this.colorOptions[colorKey]
              const barObject = {
                type: 'bar',

                label: element._id,
                data: yearData,
                backgroundColor: color,
                borderColor: 'transparent',
                borderRadius: 0,
              }
              yearBarChartDataset.push(barObject)

              if (this.trendLine === 'accepted') {
                const lineObject = {
                  type: 'line',
                  label: '',
                  data: yearData,
                  borderColor: color,
                  lineTension: 0.1,
                  pointStyle: 'circle',
                  backgroundColor: color,
                  fill: false,
                  pointRadius: 1,
                  pointHoverRadius: 5,
                  pointHoverBorderWidth: 5,
                  pointBorderColor: 'transparent',
                  pointHoverBorderColor: '#fff',
                  pointHoverBackgroundColor: color,
                  pointShadowOffsetX: 1,
                  pointShadowOffsetY: 1,
                  pointShadowBlur: 5,
                  pointShadowColor: 'rgba(0, 0, 0, 0.25)',
                }
                yearBarChartDataset.push(lineObject)
              }
            })
            if (this.yearReportTableData.length > 1) {
              const extraRow = {}

              extraRow.year = `YTY (${this.yearReportTableData[0]._id} vs ${this.yearReportTableData[1]._id})`
              extraRow.sortable = false
              this.activityTypeWithName.forEach(flow => {
                const count = this.resolveActivityTypeDataInYearPercentage(flow._id)

                extraRow[flow._id] = count
              })
              extraRow.total = this.resolveTotalActivityTypeDataInYearPercentage(this.yearReportTableData[0].totalThisYear, this.yearReportTableData[1].totalThisYear)
              this.yearTableData.push(extraRow)
            }
            // this.yearBarChartData = {
            //   labels: yearBarChartLabels,
            //   datasets: yearBarChartDataset,
            // }
            this.$set(this.yearBarChartData, 'labels', yearBarChartLabels)
            this.$set(this.yearBarChartData, 'datasets', yearBarChartDataset)
            // eslint-disable-next-line prefer-object-spread
            // this.yearBarChartData = Object.assign({}, {}, { labels: yearBarChartLabels, datasets: yearBarChartDataset })
            this.showYearReportCard = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'activityType') {
                this.activityTypeError = validationError.msg
                this.activityTypeValidation = true
              } else if (validationError.param === 'year') {
                this.yearError = validationError.msg
                this.yearValidation = true
              }
            })

            const items = document.getElementsByClassName('text-danger')
            window.scrollTo({
              top: items[0].offsetTop,
              behavior: 'smooth',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    generateMonthReport() {
      const formData = new FormData()

      formData.append('activityType', JSON.stringify(this.activityType))

      formData.append('activityTypeAll', JSON.stringify(this.onlyActivityTypeOptions.map(flow => flow._id)))
      formData.append('year', JSON.stringify(this.year))
      formData.append('month', JSON.stringify(this.monthOptionsOnly.map(month => month.key)))
      this.$http.post('operation/reports/generate/appointments/month', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.bookingsData) {
            this.monthReportData = response.data.bookingsData
            this.monthReportCountData = response.data.agentReportGrandTotalData
            this.monthTableIsSortDirDesc = false
            this.monthTableData = []
            const monthLineChartLabels = []
            const monthLineChartDataset = []
            // if (this.activityType.includes('all')) {
            //   this.activityTypeWithName = this.onlyActivityTypeOptions
            // } else {
            //
            //   this.activityTypeWithName = this.onlyActivityTypeOptions.filter(el => this.activityType.includes(el._id))
            // }
            this.monthTableColumns = []
            this.monthTableColumns.push({ key: 'agent', label: 'Agents', sortable: true })
            this.monthOptionsOnly.forEach(element => {
              this.monthTableColumns.push({ key: element.key, label: element.title, sortable: false })
              monthLineChartLabels.push(element.title)
            })
            this.monthTableColumns.push({ key: 'total', label: 'Total', sortable: false })
            response.data.bookingsData.forEach((element, key) => {
              const dataArray = []
              element.monthCountsYear.forEach(dataObj => {
                const dataObject = {}
                if (dataObj.agentCode) {
                  dataObject.agent = `${dataObj.agentCode} - ${dataObj.agentName}`
                } else {
                  dataObject.agent = 'Without Agent'
                }
                this.monthOptionsOnly.forEach(monthObj => {
                  dataObject[monthObj.key] = this.resolveActivityTypeDataInMonth(dataObj.data, monthObj.key)
                })
                dataObject.total = dataObj.count
                dataObject.sortable = true
                dataArray.push(dataObject)
              })
              const grandTotalRow = {}
              grandTotalRow.agent = 'Grand Total'
              grandTotalRow.sortable = false
              grandTotalRow.bold = true
              this.monthOptionsOnly.forEach(monthObj => {
                grandTotalRow[monthObj.key] = this.resolveActivityTypeDataInMonth(this.monthReportCountData[key].monthCounts, monthObj.key)
              })
              grandTotalRow.total = this.monthReportCountData[key].totalThisYear
              dataArray.push(grandTotalRow)
              this.monthTableData.push(dataArray)
            })
            response.data.agentReportGrandTotalData.forEach((element, colorKey) => {
              const yearData = []
              this.monthOptionsOnly.forEach(month => {
                // eslint-disable-next-line
                const monthData = element.monthCounts.find(o => o.month == month.key)
                let count = 0
                if (monthData) {
                  count = monthData.count
                }
                yearData.push(count)
              })
              const color = this.colorOptions[colorKey]
              const lineObject = {

                label: element._id,
                data: yearData,
                borderColor: color,
                lineTension: 0.1,
                pointStyle: 'circle',
                backgroundColor: '#000',
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                // pointBorderColor: 'transparent',
                // pointHoverBorderColor: '#fff',
                // pointHoverBackgroundColor: '#edcae9',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                // pointShadowColor: 'rgba(0, 0, 0, 1)',
              }
              monthLineChartDataset.push(lineObject)
            })
            // this.yearBarChartData = {
            //   labels: yearBarChartLabels,
            //   datasets: yearBarChartDataset,
            // }
            this.$set(this.monthLineChartData, 'labels', monthLineChartLabels)
            this.$set(this.monthLineChartData, 'datasets', monthLineChartDataset)
            // eslint-disable-next-line prefer-object-spread
            // this.yearBarChartData = Object.assign({}, {}, { labels: yearBarChartLabels, datasets: yearBarChartDataset })
            this.showMonthReportCard = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'activityType') {
                this.activityTypeError = validationError.msg
                this.activityTypeValidation = true
              } else if (validationError.param === 'year') {
                this.yearError = validationError.msg
                this.yearValidation = true
              }
            })

            const items = document.getElementsByClassName('text-danger')
            window.scrollTo({
              top: items[0].offsetTop,
              behavior: 'smooth',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    resolveActivityTypeDataInYear(flow, year) {
      const yearData = this.yearReportTableData.find(o => o._id === year)
      if (yearData) {
        const flowData = yearData.activityTypeCounts.find(o => o.activityTypeID === flow)
        if (flowData) {
          return flowData.count
        }
      }
      return 0
    },
    resolveActivityTypeDataInYearForMonthReport(flow, year) {
      const yearData = this.monthReportCountData.find(o => o._id === year)
      if (yearData) {
        const flowData = yearData.activityTypeCounts.find(o => o.activityTypeID === flow)
        if (flowData) {
          return flowData.count
        }
      }
      return 0
    },
    resolveActivityTypeDataForUser(flow, id) {
      const userData = this.userReportData.find(o => o._id === id)
      if (userData) {
        const flowData = userData.activityTypeCounts.find(o => o.activityTypeID === flow)
        if (flowData) {
          return flowData.count
        }
      }
      return 0
    },
    resolveTotalActivityTypeDataForUser(id) {
      const userData = this.userReportData.find(o => o._id === id)
      if (userData) {
        return userData.totalThisUser
      }
      return 0
    },
    resolveActivityTypeGrandTotalUser(id) {
      if (this.userReportGrandTotalData.length && this.userReportGrandTotalData[0].activityTypeCounts) {
        const activityTypeData = this.userReportGrandTotalData[0].activityTypeCounts.find(o => o.activityTypeID === id)
        if (activityTypeData) {
          return activityTypeData.count
        }
      }
      return 0
    },
    resolveActivityTypeDataInMonth(data, month) {
      // eslint-disable-next-line eqeqeq
      const found = data.find(o => o.month == month)
      if (found) {
        return found.count
      }
      return 0
    },
    resolveActivityTypeDataInYearPercentage(id) {
      const flowDataY1 = this.yearReportTableData[0].activityTypeCounts.find(o => o.activityTypeID === id)
      let count1 = 0
      if (flowDataY1) {
        count1 = flowDataY1.count
      }

      const flowDataY2 = this.yearReportTableData[1].activityTypeCounts.find(o => o.activityTypeID === id)
      let count2 = 0
      if (flowDataY2) {
        count2 = flowDataY2.count
      }

      const profit = count1 - count2
      let percentage = 0
      if (count1 === 0 && count2 === 0) {
        percentage = (0).toFixed(2)
      } else if (count2 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else if (count1 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else {
        percentage = ((profit / count2) * 100).toFixed(2)
      }
      if (percentage >= 0) {
        return `<span class="text-bold-black" style="color: #104D9D;">${percentage}%</span>`
      }
      return `<span class="text-bold-black" style="color: #D91B35;">${percentage}%</span>`
    },
    resolveTotalActivityTypeDataInYearPercentage(y1, y2) {
      const profit = y1 - y2
      let percentage = 0
      if (y1 === 0 && y2 === 0) {
        percentage = (0).toFixed(2)
      } else if (y2 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else if (y1 === 0) {
        percentage = (profit * 100).toFixed(2)
      } else {
        percentage = ((profit / y2) * 100).toFixed(2)
      }
      if (percentage >= 0) {
        return `<span class="text-bold-black" style="color: #104D9D;">${percentage}%</span>`
      }
      return `<span class="text-bold-black" style="color: #D91B35;">${percentage}%</span>`
    },
    // resolveYearName(id) {
    //
    //   const year = this.yearOptions.find(o => o._id === id)
    //   if (year) {
    //     return year.name
    //   }
    //   return ''
    // },
    resolveYearName(id) {
      // eslint-disable-next-line eqeqeq
      if (id == moment().utcOffset(timezone).format('YYYY')) {
        return `YTD (as at ${moment().utcOffset(timezone).format('DD/MM/YYYY')})`
      }
      // eslint-disable-next-line eqeqeq
      if (id < moment().utcOffset(timezone).format('YYYY')) {
        return `Prior Year (${id})`
      }
      return id
    },
    resolveActivityTypeName(id) {
      const flow = this.activityTypeOptions.find(o => o._id === id)
      if (flow) {
        return flow.name
      }
      return ''
    },
    resolveMonthName(id) {
      const month = this.monthOptions.find(o => o.key === id)
      if (month) {
        return month.title
      }
      return ''
    },
    submitForm() {
      this.$refs.appointmentReportForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('reportType', this.reportType)
          formData.append('singleYear', this.singleYear)
          formData.append('month', JSON.stringify(this.month))
          formData.append('activityType', JSON.stringify(this.activityType))
          formData.append('year', JSON.stringify(this.year))
          formData.append('user', JSON.stringify(this.user))
          this.$http.post('operation/reports/generate/call-logs', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'reportType') {
                    this.reportTypeError = validationError.msg
                    this.reportTypeValidation = true
                  } else if (validationError.param === 'activityType') {
                    this.activityTypeError = validationError.msg
                    this.activityTypeValidation = true
                  } else if (validationError.param === 'year') {
                    this.yearError = validationError.msg
                    this.yearValidation = true
                  } else if (validationError.param === 'user') {
                    this.userError = validationError.msg
                    this.userValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        } else {
          const items = document.getElementsByClassName('is-invalid')
          if (items && items.length) {
            // window.scrollTo({
            //   top: items[0].offsetHeight + 100,
            //   behavior: 'smooth',
            // })
            items[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }
        }
      })
    },
  },
}
</script>
